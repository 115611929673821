import {
    Core as YubinBangoCore
} from 'yubinbango-core';

export default {
    data() {
        return {
            zipRules: [
                v => (!v || /^\d{3}-?\d{4}$/.test(v) || /^\d{3}?\d{4}$/.test(v)) || '郵便番号の形式が異なります(半角数字、ハイフン)',
            ],
            address: "",
            zip: "",
        }
    },


    watch: {
        async zip(newVal) {
            try {
                if (newVal.match(/^\d{3}-?\d{4}$/) || newVal.match(/^\d{3}?\d{4}$/)) {
                    new YubinBangoCore(newVal, (addr) => {
                        this.address = addr.region // 都道府県
                        this.address += addr.locality // 市区町村
                        this.address += addr.street // 町域
                    })
                }
            } catch (error) {
                console.log()
            }
        }
    },
}