import moment from "moment";
import axios from "axios";
export default {
    data() {
        return {
            valid: false,
            subject: "",
            company: "",
            name: "",
            email: "",
            tel: "",
            time:"",
            times:[],
            text: "",
            dialog: false,
            requredRules: [v => !!v || "入力必須です"],
            message: "",
            cover: false,
            load: false,
            card: false,
            thank: false,
            booking: 0,
            otona: 1,
            kodomo: 0,
            akachan: 0,
            car: 1,
            dayModal: false,
            value: "",
            fulldays: [],
            nearFulldays: [],
            offs: [],
            events: [],
            toMonth: moment().format("yyyy年 M月"),
            month: moment().format("yyyy年 M月"),
            firstDay: moment()
                .add(1, "d")
                .format("yyyy-MM-DD"),
            lastDay: moment()
                .add(1, "d")
                .format("yyyy-MM-DD"),
            nextMonth: moment()
                .add(1, "y")
                .format("yyyy年 M月"),
            firstMonth: "",
            first: 0,
            error: "",
            show: false,
            offStart: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            offEnd: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10)
        };
    },

    async mounted() {
        const response = await axios.post("/master/contact");

        this.nearFulldays = response.data.nearFulls.map(item => item.day);
        this.fulldays = response.data.fulls.map(item => item.day);
        this.offs = response.data.offs.map(item => item.day);
        this.offStart = response.data.offStart;
        this.offEnd = response.data.offEnd;
        const events = [];

        for (let i = 0; i < this.fulldays.length; i++) {
            const first = new Date(this.fulldays[i]);
            const second = first;

            events.push({
                name: "空きなし",
                start: first,
                end: second,
                color: "rgba(0,0,0,0)",
                textColor: "rgba(0,0,0,0)"
            });
        }
        for (let i = 0; i < this.nearFulldays.length; i++) {
            const first = new Date(this.nearFulldays[i]);
            const second = first;

            events.push({
                name: "残りわずか",
                start: first,
                end: second,
                color: "rgba(0,0,0,0)",
                textColor: "rgba(0,0,0,0)"
            });
        }

        for (let i = 0; i < this.offs.length; i++) {
            const first = new Date(this.offs[i]);
            const second = first;

            events.push({
                name: "オフ",
                start: first,
                end: second,
                color: "rgba(0,0,0,0)",
                textColor: "rgba(0,0,0,0)"
            });
        }

        this.events = events;

        this.show = true;
    },

    methods: {
        getColor(event) {
            return event.textColor;
        },
        async setColor() {
            for (
                let i = 0;
                i < document.getElementsByClassName("v-event").length;
                i++
            ) {
                document.getElementsByClassName("v-event")[i].style.height =
                    "0";
            }
            for (
                let i = 0;
                i <
                document.getElementsByClassName("v-calendar-weekly__day")
                    .length;
                i++
            ) {
                document.getElementsByClassName("v-calendar-weekly__day")[
                    i
                ].style.border = "1px solid white";

                if (
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0] &&
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0]
                        .innerHTML.indexOf("残りわずか") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "rgba(255,171,15,0.4)";
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByTagName("button")[0].style.background =
                        "rgb(0,0,0,0)";
                } else if (
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0] &&
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0]
                        .innerHTML.indexOf("空きなし") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "rgba(255,0,0,0.3)";
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByTagName("button")[0].style.background =
                        "rgb(0,0,0,0)";
                } else if (
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0] &&
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByClassName("v-event")[0]
                        .innerHTML.indexOf("オフ") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "rgb(199, 181, 154)";
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByTagName("button")[0].style.background =
                        "rgb(0,0,0,0)";

                        document
                        .getElementsByClassName("v-calendar-weekly__day ")
                        [i].classList.add("noevent");
                } else {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "rgba(152,255,8,0.3)";
                    document
                        .getElementsByClassName("v-calendar-weekly__day")
                        [i].getElementsByTagName("button")[0].style.background =
                        "rgb(0,0,0,0)";
                }
            }

            for (
                let i = 0;
                i <
                document.getElementsByClassName("v-calendar-weekly__day v-past")
                    .length;
                i++
            ) {
                document
                    .getElementsByClassName("v-calendar-weekly__day v-past")
                    [i].classList.add("noevent");
                document.getElementsByClassName(
                    "v-calendar-weekly__day v-past"
                )[i].style.background = "#ddd";
            }
        }
    },

    watch: {
        value(val) {
            this.month = moment(val).format("yyyy年 M月");
        }
    }
};
